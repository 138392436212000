import { NewRelic } from './hooks/useNewRelic';
import StorageService, {
  CacheKeyType,
  CacheObject,
  IStorage,
  VERSION_PREFIX,
} from './StorageService';

class LocalStorageService extends StorageService implements IStorage {
  /**
   *
   * @param key cache key
   * @param data can be any data
   * @param options options for staletime
   * @returns
   */
  public save<T>(
    key: CacheKeyType[],
    data: T,
    options: { staleTime: number } = { staleTime: 1000 * 60 * 60 * 8 },
  ) {
    try {
      const payload = JSON.stringify(
        this.createCacheObjectToSave(data, options.staleTime),
      );
      localStorage.setItem(this.makeKey(key), payload);
    } catch (e) {
      NewRelic()?.noticeError(e);
      // eslint-disable-next-line no-console
      console.error(`ERROR saving key ${this.makeKey(key)}`, e);
      return;
    }
  }

  public retrieve<T>(key: CacheKeyType[]): T | undefined {
    try {
      const cache = localStorage.getItem(this.makeKey(key));
      if (cache === null) return undefined;

      const cacheObject = JSON.parse(cache) as CacheObject<T>;
      if (cacheObject.cacheTime + cacheObject.staleTime > Date.now()) {
        return cacheObject.payload;
      } else {
        // Cache is stale and we clear it.
        this.clearCache(key);
        return undefined;
      }
    } catch (e) {
      NewRelic()?.noticeError(e);
      // eslint-disable-next-line no-console
      console.error(`ERROR retrieving key ${this.makeKey(key)}`, e);
      return undefined;
    }
  }

  public clearCache(key: CacheKeyType[]) {
    localStorage.removeItem(this.makeKey(key));
  }

  public clear() {
    localStorage.clear();
  }

  /**
   * Will delete cache that is old so that we don't overload localstorage
   */
  public clearStaleCache() {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (!key || !key.startsWith(VERSION_PREFIX)) {
        return;
      }

      // retrieve all and by doing that clear all the stale cache
      this.retrieve(this.makeKeyFromString(key));
    }
  }
}

export default new LocalStorageService();
