export type CacheKeyType = string | number | undefined;

export type CacheObject<T> = {
  staleTime: number; // milliseconds until cache is considered stale
  cacheTime: number; // timestamp when the cache was saved
  payload: T;
};

export interface IStorage {
  save: <T>(
    key: CacheKeyType[],
    data: T,
    options: { staleTime: number },
  ) => Promise<void> | void;
}

export const VERSION_PREFIX = '@curipod-v1';

/**
 * This service handles cachekey prefix versioning
 * and abstracts away cachekey concepts as well as cachetime and staletime.
 */
class StorageService {
  makeKey(key: CacheKeyType[]) {
    return `${VERSION_PREFIX}.${key.join('.')}`;
  }
  makeKeyFromString(key: string) {
    const keyArray = key.split('.') as CacheKeyType[];
    // Remove the first element which contains VERSION_PREFIX;
    keyArray.shift();
    return keyArray;
  }

  createCacheObjectToSave<T>(payload: T, staleTime: number): CacheObject<T> {
    return {
      staleTime,
      cacheTime: Date.now(),
      payload,
    };
  }
}

export default StorageService;
