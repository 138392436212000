import { ExcalidrawElement } from '@excalidraw/excalidraw/types/element/types';

import { Game, Slide, SlideType } from './hooks/useJoinGame';
import theme from './theme';

// export const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
// export const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
// export const isIos = !!navigator.userAgent.match(/iPad|iPhone|iPod/i);

export const isSafari = false;
export const isMac = false;
export const isIos = false;

export function isIosFn() {
  return false;
  // if (/iPad|iPhone|iPod/.test(navigator.platform)) {
  //   return true;
  // } else {
  //   return (
  //     navigator.maxTouchPoints &&
  //     navigator.maxTouchPoints > 2 &&
  //     /MacIntel/.test(navigator.platform)
  //   );
  // }
}

const getAppBuildEnv = () =>
  process.env.NEXT_PUBLIC_REACT_APP_BUILD_ENV || 'local';

export const isLocal = () => {
  return getAppBuildEnv() === 'local';
};
export const isStaging = () => {
  return getAppBuildEnv() === 'dev';
};

export function generateUniqueId() {
  return Math.floor(Math.random() * Date.now());
}

export function isIpadOS() {
  return false;
  // return (
  //   navigator.maxTouchPoints &&
  //   navigator.maxTouchPoints > 2 &&
  //   /MacIntel/.test(navigator.platform)
  // );
}

export function isIOS2020() {
  return isIosFn() || isIpadOS();
}

export const ControlKey = isMac ? 'Meta' : 'Control';
export const isSafariOrIos = isSafari || isIos;

export type SlideCategory = 'response' | 'collaboration' | 'results';

export const getSlideCategory = (
  type?: SlideType,
): SlideCategory | undefined => {
  if (!type) return undefined;
  switch (type) {
    case 'answers':
    case 'aiAssessedAnswers':
    case 'aiAssessedImageAnswers':
    case 'canvas':
    case 'poll':
    case 'wordcloud':
      return 'response';
    case 'voting':
      return 'collaboration';
    case 'podium':
    case 'responsegrid':
      return 'results';
  }
};

export type SlideState = 'pre' | 'countdown' | 'in' | 'post';
export const getSlideState = ({
  game,
  now,
}: {
  game?: Game;
  now: () => number;
}): SlideState | undefined => {
  if (!game) return undefined;
  if (!game.isPlaying) return undefined;

  const currentSlide = game.slides?.find((s) => s.id === game?.currentRound);

  // The current slide has not yet started
  const isPreSlideState = game && game.roundStartsAt === null;
  if (isPreSlideState) return 'pre';

  // The current slide is starting in a few seconds
  const isSlideCountdownState =
    game && game.roundStartsAt !== null && game.roundStartsAt >= now();
  if (isSlideCountdownState) return 'countdown';

  // The current slide is done soon
  const isActiveSlideState =
    game &&
    game.roundStartsAt !== null &&
    game.roundStartsAt <= now() &&
    currentSlide &&
    currentSlide.durationSeconds &&
    game.roundStartsAt + currentSlide.durationSeconds * 1000 >= now();

  if (isActiveSlideState) return 'in';

  // The current slide was done a few seconds ago
  const isPostSlideState =
    game &&
    game.roundStartsAt !== null &&
    currentSlide &&
    currentSlide.durationSeconds &&
    game.roundStartsAt + currentSlide.durationSeconds * 1000 <= now();
  if (isPostSlideState) return 'post';
  return 'post';
};

export const getPreviousSlide = (game?: Game) => {
  if (game && game.slides) {
    const currentSlideIndex = game?.slides?.findIndex(
      (s) => s.id === game.currentRound,
    );
    if (currentSlideIndex === 0) return undefined;
    const slide = game.slides[currentSlideIndex - 1];
    return slide;
  } else {
    return undefined;
  }
};

export function findSlidesByReferencesReverse(
  startSlideId: string,
  slides: Slide[],
) {
  const startSlide = slides.find((s) => s.id === startSlideId);
  if (!startSlide) {
    throw new Error('Startslide must exist in the slides array');
  }

  const tempSlides = [startSlide];

  while (tempSlides.length <= 3) {
    const nextSlide = slides.find(
      (r) => r.dataReferenceSlideId === tempSlides[0].id,
    );
    if (nextSlide) {
      tempSlides.unshift(nextSlide);
    } else {
      break;
    }
  }
  return tempSlides.reverse();
}

export function listSlidesByReferences(startSlideId: string, slides: Slide[]) {
  const startSlide = slides.find((s) => s.id === startSlideId);
  if (!startSlide) {
    throw new Error('Startslide must exist in the slides array');
  }

  let dataReferenceSlideId = startSlide.dataReferenceSlideId;
  const tempSlides = [startSlide] as Slide[];
  while (dataReferenceSlideId) {
    // eslint-disable-next-line no-loop-func
    const relatedSlide = slides.find((s) => s.id === dataReferenceSlideId);
    if (relatedSlide) {
      tempSlides.unshift(relatedSlide);
      dataReferenceSlideId = relatedSlide.dataReferenceSlideId;
    } else {
      dataReferenceSlideId = undefined;
    }
  }
  return tempSlides;
}

export function findRelatedSlides(startSlideId: string, slides: Slide[]) {
  const referenceSlides = findRelatedSlidesWithoutIndex(startSlideId, slides);

  return referenceSlides.map((r) => ({
    ...r,
    index: slides.findIndex((s) => s.id === r.id) + 1,
  }));
}

export function findRelatedSlidesWithoutIndex(
  startSlideId: string,
  slides: Slide[],
) {
  const reverseSlides = findSlidesByReferencesReverse(startSlideId, slides);
  const last = reverseSlides[reverseSlides.length - 1];

  const referenceSlides = listSlidesByReferences(last.id, slides);
  return referenceSlides;
}

const INDEX_COLORS = [
  theme.colors.orange,
  theme.colors.green,
  theme.colors.purple,
  theme.colors.blue,
  theme.colors.red,
  theme.colors.yellow,
  theme.colors.greyLight,
  theme.colors.aqua,
  theme.colors.black,
  theme.colors.pink,
];
export const getIndexFromColor = (index: number) => {
  if (index >= INDEX_COLORS.length) {
    return INDEX_COLORS[index % INDEX_COLORS.length];
  }
  return INDEX_COLORS[index];
};

export const CURIPOD_SYSTEM_NODE_PREFIX = 'curipod_v1';

export const createStickyNote = ({
  scrollX,
  color = theme.colors.orange,
  height,
  scrollY,
  width,
  zoom,
  defaultText,
}: {
  color?: string;
  scrollX: number;
  scrollY: number;
  height: number;
  width: number;
  zoom: number;
  defaultText: string;
}): ExcalidrawElement[] => {
  const textId = generateUniqueId().toString();
  const rectangleId = generateUniqueId().toString();

  const textHeight = 25;
  const rectangleWidth = 200;
  const textWidth = rectangleWidth - 10;
  const rectangleHeight = rectangleWidth - textHeight;

  const viewPortCenterX = width / zoom / 2 - scrollX;
  const viewPortCenterY = height / zoom / 2 - scrollY;
  const stickyNoteX = viewPortCenterX - rectangleWidth / 2;
  const stickyNoteY = viewPortCenterY - rectangleHeight / 2;
  const stickyNoteTextX = stickyNoteX + 5;
  const stickyNoteTextY = stickyNoteY + rectangleHeight / 2 - textHeight / 2;

  return [
    {
      angle: 0,
      backgroundColor: color,
      boundElements: [{ id: textId, type: 'text' }],
      fillStyle: 'solid',
      groupIds: [],
      height: rectangleHeight,
      id: rectangleId,
      isDeleted: false,
      link: null,
      locked: false,
      opacity: 100,
      roughness: 1,
      seed: 393274176,
      strokeColor: 'transparent',
      strokeSharpness: 'sharp',
      strokeStyle: 'solid',
      strokeWidth: 1,
      type: 'rectangle',
      updated: 1656837112487,
      version: 14,
      versionNonce: 743356608,
      width: rectangleWidth,
      x: stickyNoteX,
      y: stickyNoteY,
    },
    {
      angle: 0,
      backgroundColor: 'transparent',
      baseline: 18,
      boundElements: null,
      containerId: rectangleId,
      fillStyle: 'solid',
      fontFamily: 1,
      fontSize: 20,
      groupIds: [],
      height: textHeight,
      id: textId,
      isDeleted: false,
      link: null,
      locked: false,
      opacity: 100,
      originalText: defaultText,
      roughness: 1,
      seed: 1354226496,
      strokeColor: '#000000',
      strokeSharpness: 'sharp',
      strokeStyle: 'solid',
      strokeWidth: 1,
      text: defaultText,
      textAlign: 'center',
      type: 'text',
      updated: 1656836955422,
      version: 12,
      versionNonce: 382927040,
      verticalAlign: 'middle',
      width: textWidth,
      x: stickyNoteTextX,
      y: stickyNoteTextY,
    },
  ];
};

export const parseSlateTextToText = (slateText?: string) => {
  if (!slateText) return '';
  if (!slateText.startsWith('[{')) return slateText;
  try {
    const slateObject = JSON.parse(slateText);
    return slateObject[0].children[0].text;
  } catch (error) {
    return '';
  }
};
export function notUndefined<T>(x: T | undefined): x is T {
  return x !== undefined;
}
