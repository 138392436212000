import { useEffect } from 'react';

import { isLocal } from '../utils';

const useBeforeUnload = () => {
  useEffect(() => {
    const fnRef = function (e: BeforeUnloadEvent) {
      // Cancel the event
      e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
      // Chrome requires returnValue to be set
      e.returnValue = '';

      // Safari doesnt catch this but you can navigate back and forth
    };
    if (!isLocal()) {
      window.addEventListener('beforeunload', fnRef);
    }
    return () => {
      if (!isLocal()) {
        window.removeEventListener('beforeunload', fnRef);
      }
    };
  }, []);

  return null;
};
export default useBeforeUnload;
