'use client';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { PageViewTracker } from './PageViewTracker';
import { useEffect } from 'react';

export function AnalyticsProvider({ children }) {
  useEffect(() => {
    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
      api_host: '/api/ph', // Target the proxy
      person_profiles: 'identified_only', // Manually track profiles
      loaded: function (ph) {
        if (process.env.NEXT_PUBLIC_APP_LOCAL == 'true') {
          ph.opt_out_capturing(); // opts a user out of event capture
          ph.set_config({ disable_session_recording: true });
        }
      },
    });

    // Use register to easily filter events coming from participants.
    posthog.register({
      isParticipant: true,
    });
  }, []);
  return (
    <PostHogProvider client={posthog}>
      <>{children}</>
      <PageViewTracker />
    </PostHogProvider>
  );
}
