import { isLocal, isStaging } from '../utils';
import { config } from './environments.production';

export const getConfig = () => {
  let appConfig = config;
  if (isLocal()) {
    const devConfig = require('../config/environments.local').config;
    appConfig = devConfig;
  }
  if (isStaging()) {
    const devConfig = require('../config/environments').config;
    appConfig = devConfig;
  }
  return appConfig;
};

export const configureConfig = () => {
  const config = getConfig();

  return { config };
};
