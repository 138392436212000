export const config = {
  backend: {
    coreApiUrl: 'https://api-test.curipod.com',
    liveApiUrl: 'https://dev-api-live.curipod.com',
  },
  signupUrl: 'https://app-dev.curipod.com/signup',
  unleash: {
    url: 'https://unleash-proxy-7epohblsoa-ey.a.run.app/proxy',
    clientKey: '7epohblsoa',
    appName: 'app-curipod-dev',
    // refreshInterval: 60 * 5,
  },
};

export type APPConfig = typeof config;
