export const config = {
  backend: {
    coreApiUrl: 'http://localhost:3010',
    liveApiUrl: 'http://localhost:3011',
  },
  signupUrl: 'https://localhost:3000/signup',
  unleash: {
    url: 'https://unleash-proxy-7epohblsoa-ey.a.run.app/proxy',
    clientKey: '7epohblsoa',
    appName: 'app-curipod',
    // refreshInterval: 60 * 5,
  },
};
