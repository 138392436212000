import axios from 'axios';
import retry from 'async-retry';

import pkg from '../../package.json';
import { CropConfig } from '../hooks/useJoinGame';
import { ById } from '../typings/types';
import { getDestinationTenantFromUrl } from '../utils/navigator';

const getUnAuthHeader = (destinationTenantId?: string) => {
  const base: ById<string> = {
    'x-app-version': `v${pkg.version}`,
  };
  if (destinationTenantId) {
    base['x-api-tenant'] = destinationTenantId;
  }
  return base;
};

export default class BackendService {
  static instance: BackendService;
  url = '';
  idToken: string | null = null;
  deferredRefreshRequests: { resolve: () => void; reject: () => void }[] = [];
  isRefreshingTokens = false;
  constructor() {
    if (BackendService.instance) {
      return BackendService.instance;
    }
    BackendService.instance = this;
    return this;
  }

  public init(url: string) {
    BackendService.instance.url = url;

    if (process.env.isLocal) {
      BackendService.instance.url = 'http://localhost:3010';
    }
  }
  public async getPublicDownloadUrl(
    filename: string,
    tenantId: string,
    folder?: string,
  ) {
    return await axios.post<{ url: string }>(
      `${this.url}/v0.5/files/public/downloadUrl`,
      { filename: filename, folder },
      {
        headers: {
          ...getUnAuthHeader(tenantId),
        },
      },
    );
  }
  gameFiles = {
    fetchUauthenticatedCroppedImage: async ({
      fileId,
      isFromDiscover,
      cropConfig,
      tenantId,
    }: {
      fileId: string;
      cropConfig?: CropConfig | null;
      isFromDiscover: boolean;
      tenantId?: string;
    }) =>
      await retry(async (retrier, attemptNumber) => {
        const query = cropConfig
          ? `?h=${cropConfig.height}&w=${cropConfig.width}&x=${cropConfig.x}&y=${cropConfig.y}`
          : undefined;
        if (isFromDiscover) {
          const url = `${this.url}/v0.5/discover/public/filesV2/${fileId}${
            query ? query : ''
          }`;

          const res = await axios.request<string>({
            method: 'get',
            url,
            headers: {
              ...(attemptNumber > 1 && { 'Cache-Control': 'no-cache' }),
            },
          });

          const signedUrl = res.data;

          //we want to prefetch the image so also get the image blob from cdn
          const cdnImageResponse = await axios.request<Blob>({
            method: 'get',
            url: signedUrl,
            responseType: 'blob',
          });
          const objectUrl = URL.createObjectURL(cdnImageResponse.data);
          return objectUrl;
        }
        // This image is not from discover - we use game_images endpoints

        const url = `${this.url}/v0.5/game_images/public/filesV2/${fileId}${
          query ? query : ''
        }`;

        const res = await axios.request<string>({
          method: 'get',
          url,
          headers: {
            ...getUnAuthHeader(tenantId),
            ...(attemptNumber > 1 && { 'Cache-Control': 'no-cache' }),
          },
        });

        const signedUrl = res.data;

        //we want to prefetch the image so also get the image blob from cdn
        const cdnImageResponse = await axios.request<Blob>({
          method: 'get',
          url: signedUrl,
          responseType: 'blob',
        });
        const objectUrl = URL.createObjectURL(cdnImageResponse.data);
        return objectUrl;
      }),
    fetchSvgImage: async ({
      fileId,
      cropConfig,
      tenantId,
    }: {
      fileId: string;
      cropConfig?: CropConfig | null;
      tenantId: string;
    }) =>
      await retry(async (retrier, attemptNumber) => {
        const query = cropConfig
          ? `?h=${cropConfig.height}&w=${cropConfig.width}&x=${cropConfig.x}&y=${cropConfig.y}`
          : undefined;

        const url = `${this.url}/v0.5/game_images/public/filesV2/${fileId}${
          query ? query : ''
        }`;

        const res = await axios.request<string>({
          method: 'get',
          url,
          headers: {
            ...getUnAuthHeader(tenantId),
            ...(attemptNumber > 1 && { 'Cache-Control': 'no-cache' }),
          },
        });

        const signedUrl = res.data;

        //we want to prefetch the image so also get the image blob from cdn
        const cdnImageResponse = await axios.request({
          method: 'get',
          url: signedUrl,
        });

        return cdnImageResponse.data;
      }),
  };
}
