/**
 * Hack to be able to send custom metrics.
 */

/**
 * Use this when not in the react context
 */
export const NewRelic = () => window.newrelic;

/**
 * Use this in react components to send metrics.
 */
export const useNewRelic = () => {
  return {
    newRelic: NewRelic(),
  };
};
