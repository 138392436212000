import { createStore, delMany, getMany, keys, set } from 'idb-keyval';

import StorageService, { CacheObject, IStorage } from './StorageService';
import { notUndefined } from './utils';

export type CacheKeyType = string | number | undefined;
const IDBStore = createStore('curipod-db', 'files-store');

class IndexedDBStorageService extends StorageService implements IStorage {
  /**
   *
   * @param key cache key
   * @param data can be any data
   * @param options options for staletime
   * @returns
   */
  public async save<T>(
    key: CacheKeyType[],
    data: T,
    options: { staleTime: number } = { staleTime: 1000 * 60 * 60 * 8 },
  ) {
    try {
      const saveData = this.createCacheObjectToSave(data, options.staleTime);
      await set(this.makeKey(key), saveData, IDBStore);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error saving file', e);
    }
  }
  public async saveMany<T>(
    data: {
      key: CacheKeyType[];
      data: T;
      options: { staleTime: number };
    }[],
  ) {
    try {
      await Promise.all(
        data.map(async ({ key, data, options }) => {
          await this.save(key, data, options);
        }),
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error saving file', e);
    }
  }

  private async retrieveMany<T>(keys: CacheKeyType[][]) {
    const ids = keys.map(this.makeKey);
    const cacheObjects = await getMany<CacheObject<T>>(ids, IDBStore);
    if (!cacheObjects) return undefined;
    const result = cacheObjects.filter(notUndefined).reduce<{
      loadedData: T[];
      staleKeys: CacheKeyType[][];
    }>(
      (acc, cacheObject, index) => {
        if (cacheObject.cacheTime + cacheObject.staleTime > Date.now()) {
          return {
            ...acc,
            loadedData: [...acc.loadedData, cacheObject.payload],
          };
        } else {
          const key = keys[index];
          return {
            ...acc,
            staleKeys: [...acc.staleKeys, key],
          };
        }
      },
      { loadedData: [], staleKeys: [] },
    );

    return result;
  }

  public async retrieveManyAndDeleteStale<T>(keys: CacheKeyType[][]) {
    const result = await this.retrieveMany<T>(keys);
    if (!result) return undefined;

    // Delete all stale data
    await this.deleteMany(result.staleKeys);
    return result.loadedData;
  }

  public async deleteMany(keys: CacheKeyType[][]) {
    await delMany(keys.map(this.makeKey), IDBStore);
  }

  /**
   * Clears all cache that is stale.
   */
  public async clearStaleCache() {
    const allKeys = await keys(IDBStore);
    const keyStrings = allKeys.map((key) =>
      this.makeKeyFromString(key.toString()),
    );
    await this.retrieveManyAndDeleteStale(keyStrings);
  }
}

export default new IndexedDBStorageService();
